let showPasswordCheck = document.querySelector("#showPasswordCheck");
let password = document.querySelector("#inputPassword");
showPasswordCheck.addEventListener("change", onPasswordToggleChange);
function onPasswordToggleChange(e) {
    setTimeout(function () {
        // if checkbox is checked, show password
        if (e.target.checked) {
            password.type = "text";

            // if checkbox is off, hide password
        } else {
            password.type = "password";
        }
    }, 100);
}
jQuery(function($){
    // Start

});